import { ButtonSize, ButtonSizeType } from '@aimmo/design-system/aim-button-model/src';
import { AimIconComponent } from '@aimmo/design-system/aim-icon/src';
import { Platform } from '@angular/cdk/platform';
import {
  ANIMATION_MODULE_TYPE,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  NgZone,
  Optional
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { AimButtonHostingDirective } from '../../shared/directives/aim-button-hosting.directive';

@Component({
  selector: '[aim-icon-button], aim-icon-button',
  templateUrl: './aim-icon-button.component.html',
  styleUrls: ['./aim-icon-button.component.scss'],
  exportAs: 'aimIconButton, aim-icon-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: AimButtonHostingDirective,
      inputs: [
        'buttonTheme',
        'buttonSize'
      ],
    }
  ],
  standalone: true,
  imports: [AimIconComponent]
})
export class AimIconButtonComponent extends MatButton {
  @Input() public icon: string;
  @Input() public buttonSize: ButtonSizeType = ButtonSize.large;
  @Input() public isActive = false; // TODO: useActive == false 상태에서 동작하는 게 맞는 지 확인 필요.
  @Input() public useActive = true;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    platform: Platform,
    ngZone: NgZone,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) animationMode?: string,
  ) {
    super(elementRef, platform, ngZone, animationMode);
  }

  public get ref(): ElementRef<HTMLElement> {
    return this.elementRef;
  }

  @HostBinding('class.active')
  public get onActive(): boolean {
    return this.isActive;
  }

  @HostBinding('class.disabled')
  private get isDisabled(): boolean {
    return this.disabled;
  }

  @HostListener('click')
  private onClick(): void {
    if (this.useActive) {
      this.isActive = !this.isActive;
    }
  }
}
